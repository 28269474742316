const design = [
  { id: 1, name: "Figma", image: "svg/figma-logo.svg" },
  { id: 2, name: "Adobe XD", image: "svg/xd-logo.svg" },
  { id: 3, name: "Adobe Il", image: "svg/ai-logo.svg" },
  { id: 4, name: "Adobe Ps", image: "svg/ps-logo.svg" },
  { id: 5, name: "Adobe Pr", image: "svg/pr-logo.svg" },
  { id: 6, name: "Adobe Ae", image: "svg/ae-logo.svg" },
];

export default design;
